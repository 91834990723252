import React from "react";
import DataGrid, {
  Column,
  Editing,
  Paging,
  Lookup,
  Form,
  Pager,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Item,
} from "devextreme-react/data-grid";

import { fetchcompany } from "../../../api/MyOwnServices";
// /api/MyOwnServices";

import { useAuth } from "../../../contexts/auth";
import { assetStore, getAssetTypes } from "./clientAssetsData";
import ClientAssetDetails from "./clientAssetDetails"; // Importing ClientAssetDetails component
//import "./clientAssets.scss"; // Importing CSS file

const allowedPageSizes = [8, 12, 24];

class ClientAssetsx extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentRow: null,
      selectedRowKeys: [],
      companyCode: 1,
      showFilterRow: true,
      showHeaderFilter: true,
      currentFilter: "auto",
      assetTypes: [],
      assetsData: [],
      basecurrency: "",
    };
  }

  refreshAssetsData = () => {
    const assetsData = assetStore(this.props.clientCode); // Re-fetch data
    this.setState({ assetsData });
  };

  componentDidMount() {
    const assetsData = assetStore(this.props.clientCode); // Assume this returns an array
    this.setState({ assetsData });

    // Fetch asset types asynchronously
    getAssetTypes().then((data) => {
      this.setState({ assetTypes: data.data });
    });

    // Log the company number
    console.log("companynumbersent now ", this.props.companynumbersent);

    // Handle the asynchronous fetchcompany call with .then()
    fetchcompany(this.props.companynumbersent)
      .then((companyValues) => {
        console.log("company value: ", companyValues);

        // Set the basecurrency once the promise resolves
        this.setState({ basecurrency: companyValues.basecurrency }, () => {
          console.log("basecurrency now ", this.state.basecurrency);
        });
      })
      .catch((error) => {
        console.error("Error fetching company values: ", error);
      });
  }

  handleSelectionChanged = (e) => {
    console.log("selection changed");
    if (e.selectedRowKeys.length > 0) {
      const currentRow = e.selectedRowKeys[0];
      this.setState({ currentRow });
    }
  };

  handleSelectionMade = (e) => {
    console.log("selection made - date is ", e.data);
    this.setState({ currentRow: e.data.UNIQUEID });
    // }
  };

  handleInitNewRow = (e) => {
    e.data.CURRENCY = this.state.basecurrency; // Set the default currency to basecurrency
  };

  render() {
    const { currentRow, assetsData } = this.state;

    return (
      // <div className="content-block2 dx-card">
      <div>
        <DataGrid
          dataSource={assetsData}
          keyExpr="UNIQUEID"
          showBorders={true}
          remoteOperations={false}
          onSelectionChanged={this.handleSelectionChanged}
          onEditingStart={this.handleSelectionMade}
          onInitNewRow={this.handleInitNewRow}
          width={"100%"}
          columnAutoWidth={true}
        >
          <FilterRow
            visible={this.state.showFilterRow}
            applyFilter={this.state.currentFilter}
          />
          <HeaderFilter visible={this.state.showHeaderFilter} />
          <SearchPanel visible={false} width={240} placeholder="Search..." />
          <Paging enabled={true} />

          <Editing
            mode="form" // Embedding related data grid inside form
            allowUpdating={true}
            allowAdding={true}
            allowDeleting={true}
          >
            <Form
              colCount={5}
              width="100%"
              showBorders={true}
              style={{
                border: "red", // Wide red border
                padding: "10px", // Optional: add padding inside the border
              }}
            >
              <Item dataField="ASSETNAME" />
              <Item dataField="ASSETTYPE">
                <Lookup
                  dataSource={this.state.assetTypes}
                  valueExpr="ASSETTYPE"
                  displayExpr="DESCRIPTION"
                />
              </Item>
              <Item dataField="CURRENCY" />
              <Item dataField="LASTPOSTEDDATE" allowEditing={false} />
              <Item
                dataField="CURRENTVALUE"
                allowEditing={false}
                editorType="dxNumberBox"
                editorOptions={{ format: "$###,###,###.00" }}
                alignment="right"
                //format={"currency"} // Use "currency" for currency formatting
              />
              <Item />
              <Item colSpan={4}>
                <ClientAssetDetails
                  rowid={currentRow}
                  clientCode={this.props.clientCode}
                  onRefresh={this.refreshAssetsData} // This will trigger a refresh
                />
              </Item>
            </Form>
          </Editing>

          {/* Main Columns */}
          <Column dataField={"UNIQUEID"} caption={"ID"} visible={false} />
          <Column dataField={"ASSETNAME"} caption="Asset Name" width={300} />
          <Column dataField={"CURRENCY"} caption={"Currency"} width={150} />
          <Column dataField={"ASSETTYPE"} caption={"Asset Type"} width={200}>
            <Lookup
              dataSource={this.state.assetTypes}
              valueExpr="ASSETTYPE"
              displayExpr="DESCRIPTION"
            />
          </Column>
          <Column
            dataField={"LASTPOSTEDDATE"}
            caption={"Last Posted Date"}
            dataType="date"
            allowEditing={false}
          />
          <Column
            dataField={"CURRENTVALUE"}
            caption={"Current Value"}
            editorType="dxNumberBox"
            format={"$###,###,###.00"}
            alignment="right"
            allowEditing={false}
          />

          <Paging defaultPageSize={8} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={allowedPageSizes}
          />
        </DataGrid>
      </div>
    );
  }
}

export default function ClientAssets() {
  const { user } = useAuth();
  const defaultcompany = 1;
  return (
    <ClientAssetsx
      clientCode={user.thisClientcode}
      companynumbersent={defaultcompany}
    />
  );
}
