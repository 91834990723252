export const getClients = async () => {
  const companynumber = 1;
  var requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      SentCompany: companynumber,
    }),
  };
  const url = `${process.env.REACT_APP_BASE_URL}/getClientData`;
  return fetch(url, requestoptions) // Request fish
    .then((response) => {
      //console.log("company:", companynumber);
      if (!response.ok) {
        return {
          companyname: "System did not respond",
          returnaddress: " ",
        };
      }
      return response.json();
    })
    .then((json) => {
      //console.log("data stuff", json);
      return {
        data: json.user_response.loginq.map((item) => ({
          label: item.CLIENTCODE,
          value: item.UNIQUEID,
          inactive: item.INACTIVE,
        })),
        totalCount: json.user_response.totalCount,
        key: json.user_response.keyname,
        // data: json.user_response.mdata,
        // totalCount: json.user_response.totalCount,
        // key: json.user_response.keyname,
      };
    });
};
export const fetchThisClientData = async (clientCode) => {
  //console.log("client in:", clientCode);
  var requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      requestedclientcode: clientCode,
    }),
  };
  const url = `${process.env.REACT_APP_BASE_URL}/getClientCode`;
  return fetch(url, requestoptions) // Request fish
    .then((response) => {
      //console.log("company:", companynumber);
      if (!response.ok) {
        return {
          companyname: "System did not respond",
          returnaddress: " ",
        };
      }
      return response.json();
    })
    .then((json) => {
      //console.log("data stuff", json);
      return {
        CLIENTCODE: json.user_response.bankq.CLIENTCODE,
        NAME: json.user_response.bankq.NAME,
        ADDRESSLINEONE: json.user_response.bankq.ADDRESSLINEONE,
        ADDRESSLINETWO: json.user_response.bankq.ADDRESSLINETWO,
        ADDRESSLINETHREE: json.user_response.bankq.ADDRESSLINETHREE,
        ADDRESSLINEFOUR: json.user_response.bankq.ADDRESSLINEFOUR,
        COUNTRY: json.user_response.bankq.COUNTRY,
        POSTALZIP: json.user_response.bankq.POSTALZIP,
        UNIQUEID: json.user_response.bankq.UNIQUEID,
        STARTDATE: json.user_response.bankq.STARTDATE,
        ENDDATE: json.user_response.bankq.ENDDATE,
        STARTYEARINTEREST: json.user_response.bankq.STARTYEARINTEREST,
        STARTMONTHINTEREST: json.user_response.bankq.STARTMONTHINTEREST,
        ENDYEARINTEREST: json.user_response.bankq.ENDYEARINTEREST,
        ENDMONTHINTEREST: json.user_response.bankq.ENDMONTHINTEREST,
        startinterestperiod: json.user_response.bankq.STARTINTEREST,
        endinterestperiod: json.user_response.bankq.ENDINTEREST,
        PRESENTATIONDATE: json.user_response.bankq.PRESENTATIONDATE,
        WEBTRANSFERLOCATION: json.user_response.bankq.WEBTRANSFERLOCATION,
      };
    });
};
export const updateClient = async (requestedclientcode, companyValues) => {
  console.log("client in:", requestedclientcode);
  //companyValues.AddressLineThree = "corner light";
  var requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      clientcode: requestedclientcode,
      Name: companyValues.Name,
      AddressLineOne: companyValues.AddressLineOne,
      AddressLineTwo: companyValues.AddressLineTwo,
      AddressLineThree: companyValues.AddressLineThree,
      AddressLineFour: companyValues.AddressLineFour,
      Country: companyValues.Country,
      PostalZip: companyValues.PostalZip,
      UniqueID: companyValues.UniqueID,
      startdate: companyValues.startdate,
      enddate: companyValues.enddate,
    }),
  };
  const url = `${process.env.REACT_APP_BASE_URL}/updateClientDataAdmin`;
  return await fetch(url, requestoptions) // Request fish
    .then((response) => {
      if (!response.ok) {
        return {
          companyname: "System did not respond",
          returnaddress: " ",
        };
      }
      return response.json();
    })
    .then((json) => {
      return {};
    });
};

///////////////////////////////////////////////////////
///const updateCurrentClient = (clientCode) => {  }
//////////////////////////////////////////////////////
export const updateCurrentUser = async (usercode, clientcode) => {
  //console.log("client in:", requestedclientcode);
  //companyValues.AddressLineThree = "corner light";
  var requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      usercode: usercode,
      clientcode: clientcode,
    }),
  };
  const url = `${process.env.REACT_APP_BASE_URL}/updateClientDataAdmin`;
  return await fetch(url, requestoptions) // Request fish
    .then((response) => {
      if (!response.ok) {
        return {
          companyname: "System did not respond",
          returnaddress: " ",
        };
      }
      return response.json();
    })
    .then((json) => {
      return {};
    });
};

export const updateClientx = async (
  requestedclientcode,
  startdate,
  enddate
) => {
  console.log("client in:", requestedclientcode);
  //companyValues.AddressLineThree = "corner light";
  var requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      clientcode: requestedclientcode,
      startdate: startdate,
      enddate: enddate,
    }),
  };
  const url = `${process.env.REACT_APP_BASE_URL}/updateClientDataAdminDate`;
  return await fetch(url, requestoptions) // Request fish
    .then((response) => {
      if (!response.ok) {
        return {
          companyname: "System did not respond",
          returnaddress: " ",
        };
      }
      return response.json();
    })
    .then((json) => {
      return {};
    });
};

export const UpdateCFIncomeData = async (
  currentClientCode,
  presentationDate
) => {
  //console.log("client in:", requestedclientcode);
  //companyValues.AddressLineThree = "corner light";
  var requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      clientCode: currentClientCode,
      presentationDate: presentationDate,
    }),
  };
  const url = `${process.env.REACT_APP_BASE_URL}/updateClientCFIncome`;
  return await fetch(url, requestoptions) // Request fish
    .then((response) => {
      if (!response.ok) {
        return {
          companyname: "System did not respond",
          returnaddress: " ",
        };
      }
      console.log("response", response);
      return {
        returnerrormessage: response.json.returnerrormessage,
        successmessage: response.json.successmessage,
      };
    })
    .then((json) => {
      return {};
    });
};

export const GenerateCarryingChargesData2 = async (
  currentClientCode,
  presentationDate
) => {
  const url = `${process.env.REACT_APP_BASE_URL}/GenerateExcelFile`;
  let filesent = false;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      ClientCode: currentClientCode,
      presentationDate: presentationDate,
    }),
  };

  console.log("Request sent to create Excel file");

  try {
    // Step 1: Send the initial request to create the Excel file
    console.log("Waiting for file creation");
    const response = await fetch(url, requestOptions);

    console.log("Response received:", response);

    if (!response.ok) {
      console.error("Failed to create the file:", response.statusText);
      return {
        companyname: "System did not respond",
        returnaddress: " ",
      };
    } else {
      filesent = true;
    }

    // Step 2: Process the response to confirm file creation
    //const result = await response.json();
    console.log("Response JSON:", filesent);

    if (filesent === true) {
      console.log("File created successfully, starting transfer process");

      // Step 3: Call the new routine to transfer the file
      const transferResult = await GenerateExcelFileForSend(currentClientCode); // Assuming `fileId` is returned
      return transferResult;
    } else {
      console.error("File creation failed:"); //, result);
      return {
        returnerrormessage: "File creation failed",
      };
    }
  } catch (error) {
    console.error("Error occurred during the process:", error);
    return {
      returnerrormessage: "An error occurred during the file creation",
    };
  }
};

// New routine to transfer the created file
export const GenerateExcelFileForSend = async (currentClientCode) => {
  const uploadUrl = `${process.env.REACT_APP_BASE_URL}/GenerateExcelFileForSend`;

  const uploadRequestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      currentClientCode: currentClientCode, // Assuming fileId is needed to identify the file
    }),
  };

  console.log("Request sent to transfer Excel file");

  try {
    // Send the request to transfer the file
    const uploadResponse = await fetch(uploadUrl, uploadRequestOptions);
    console.log("Response received:", uploadResponse);

    if (!uploadResponse.ok) {
      console.error("Failed to transfer the file:", uploadResponse.statusText);
      return {
        returnerrormessage: "An error occurred during the file transfer",
      };
    }

    const uploadResult = await uploadResponse.json();
    console.log("File transfer response:", uploadResult);

    if (uploadResult.success) {
      console.log("File transferred successfully");
      return {
        successmessage: "File created and transferred successfully",
      };
    } else {
      return {
        returnerrormessage: "File transfer failed",
      };
    }
  } catch (error) {
    console.error("Error occurred during file transfer:", error);
    return {
      returnerrormessage: "An error occurred during the file transfer",
    };
  }
};

export const GenerateCarryingChargesData = async (
  currentClientCode,
  presentationDate
) => {
  var requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      ClientCode: currentClientCode,
      presentationDate: presentationDate,
    }),
  };

  const url = `${process.env.REACT_APP_BASE_URL}/GenerateExcelFile`;

  return await fetch(url, requestOptions)
    .then((response) => {
      if (!response.ok) {
        return {
          companyname: "System did not respond",
          returnaddress: " ",
        };
      }
      return response.json(); // Parse the JSON response
    })
    .then((json) => {
      console.log("Parsed JSON:", json); // Log the parsed JSON for debugging

      return {
        filename: json.user_response?.newfilename || "No filename returned",
      };
    })
    .catch((error) => {
      console.error("Error occurred:", error);
      return {
        errorMessage: "An error occurred while generating the file",
      };
    });
};

export const ProcessAccountStatement = async (
  currentClientCode,
  presentationDate
) => {
  var requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      ClientCode: currentClientCode,
      presentationDate: presentationDate,
    }),
  };

  const url = `${process.env.REACT_APP_BASE_URL}/GenerateExcelFile`;

  return await fetch(url, requestOptions)
    .then((response) => {
      if (!response.ok) {
        return {
          companyname: "System did not respond",
          returnaddress: " ",
        };
      }
      return response.json(); // Parse the JSON response
    })
    .then((json) => {
      console.log("Parsed JSON:", json); // Log the parsed JSON for debugging

      return {
        filename: json.user_response?.newfilename || "No filename returned",
      };
    })
    .catch((error) => {
      console.error("Error occurred:", error);
      return {
        errorMessage: "An error occurred while generating the file",
      };
    });
};

export const GenerateAccountStatementData = async (
  myClientCode,
  currentBankAccount,
  Segment,
  startPeriod,
  endPeriod,
  allAccounts
) => {
  var requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      myClientCode: myClientCode,
      currentBankAccount: currentBankAccount,
      Segment: Segment,
      startPeriod: startPeriod,
      endPeriod: endPeriod,
      allAccounts: allAccounts,
    }),
  };

  console.log(
    "Request sent to generate account statement",
    myClientCode,
    currentBankAccount,
    Segment,
    startPeriod,
    endPeriod,
    allAccounts
  );

  const url = `${process.env.REACT_APP_BASE_URL}/GenerateAccountStatementV3`;

  return await fetch(url, requestOptions)
    .then((response) => {
      if (!response.ok) {
        return {
          companyname: "System did not respond",
          returnaddress: " ",
        };
      }
      return response.json(); // Parse the JSON response
    })
    .then((json) => {
      console.log("Parsed JSON:", json); // Log the parsed JSON for debugging

      return {
        filename: json.user_response?.newfilename || "No filename returned",
      };
    })
    .catch((error) => {
      console.error("Error occurred:", error);
      return {
        errorMessage: "An error occurred while generating the file",
      };
    });
};
