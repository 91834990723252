import React, { useState, useEffect, useRef } from "react";
import ExcelJS from "exceljs";
import withReactContent from "sweetalert2-react-content";
import { saveAs } from "file-saver";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { useAuth } from "../../../contexts/auth";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  Paging,
  FilterRow,
  HeaderFilter,
  Editing,
  Lookup,
  Export,
} from "devextreme-react/data-grid";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import jsPDF from "jspdf";
import {
  CFIncomeData,
  mystore11,
  getCFIncomeTypesDataFilter,
  ResequenceIncomeData,
} from "./cfData";

import { mystore7, mystore8 } from "./segmentData";
import CFStructureResultInvestments from "./cfStructureResultInvestments";
import CFStructureResultDebts from "./cfStructureResultDebts";
import { fetchcompany } from "../../../api/MyOwnServices";
import { GenerateNetWorthExcel } from "./generateNetWorthExcel";
import { fetchThisClientData } from "./segmentData";
import "./debtSummaryStyles.scss";
import "./debtsummary.css";
import "./app.scss";

const CombinedComponentx = (props) => {
  const [OwnerNames, setOwnerNames] = useState([]);
  const [StatementTypes, setStatementTypes] = useState([]);
  const [TaxStatementType, setTaxStatementType] = useState("");
  const [companynumber, setCompanyNumber] = useState(1);
  const [UpdateType, setUpdateType] = useState([]);
  const [loadForm, setLoadForm] = useState(false);
  const [ShowCreateClientPresentation, setShowCreateClientPresentation] =
    useState(props.ShowCreateClientPresentation);
  const [resetKey, setResetKey] = useState(0);
  const [currentRow, setCurrentRow] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showFilterRow, setShowFilterRow] = useState(false);
  const [showHeaderFilter, setShowHeaderFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editExcelOn, setEditExcelOn] = useState(false);
  const dataGridRef = useRef(null); // Using useRef to create a reference
  const [debtType, setDebtType] = useState(1);
  const [investmentType, setInvestmentType] = useState(2);

  const { user } = useAuth();

  const [companyValues, setCompanyValues] = useState({
    CLIENTCODE: "",
    NAME: "",
    ADDRESSLINEONE: "",
    ADDRESSLINETWO: "",
    ADDRESSLINETHREE: "",
    ADDRESSLINEFOUR: "",
    COUNTRY: "",
    POSTALZIP: "",
    UNIQUEID: "",
    STARTDATE: "",
    ENDDATE: "",
    COPPERID: "",
    PRESENTATIONDATE: "",
    PRESENTATIONDATE2: "",
    PRESENTATIONNAME: "",
  });

  useEffect(() => {
    (async () => {
      try {
        const result = await fetchThisClientData(props.clientCode);
        setCompanyValues(result);
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    })();
  }, [props.clientCode]);

  useEffect(() => {
    if (ShowCreateClientPresentation) {
      setLoadForm(true);
    }

    mystore11(props.clientCode)
      .then((data) => {
        setOwnerNames(data.data);
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the transaction group data:",
          error
        );
      });
    fetchcompany(companynumber).then((result) => {
      setTaxStatementType(result.StructureStatementType);
      setUpdateType(result.TaxStatementType);
    });
  }, [props.clientCode, companynumber]);

  useEffect(() => {
    if (TaxStatementType && TaxStatementType.trim() !== "") {
      getCFIncomeTypesDataFilter(TaxStatementType)
        .then((data) => {
          setStatementTypes(data.data);
        })
        .catch((error) => {
          console.error(
            "There was an error fetching the transaction group data:",
            error
          );
        });
    }
  }, [TaxStatementType]);

  const handleInitNewRow = (e) => {
    if (TaxStatementType && TaxStatementType.trim() !== "") {
      e.data.REPORTTYPE = TaxStatementType;
    }
  };

  const LoadForm = () => {
    loadForm ? setLoadForm(false) : setLoadForm(true);
  };

  const calculateTotalAmount = (amountOne, amountTwo) => {
    const amt1 = parseFloat(amountOne) || 0;
    const amt2 = parseFloat(amountTwo) || 0;
    return amt1 + amt2;
  };

  const ResequenceIncome = () => {
    ResequenceIncomeData(props.clientCode, TaxStatementType).then((data) => {
      setResetKey(resetKey + 1);
    });
  };

  const CreateExcel = () => {
    setEditExcelOn(true);
  };

  const handleMappingUpdated2 = (value) => {
    setEditExcelOn(false);
  };

  const handleSelectionChanged = (e) => {
    setSelectedRowKeys(e.selectedRowKeys);
    if (e.selectedRowKeys.length > 0) {
      setCurrentRow(e.selectedRowKeys[0]);
    }
  };

  const handleEditingStart = (e) => {
    const rowToBeEdited = e.data;
    if (rowToBeEdited.someField === "someValue") {
      e.cancel = true;
    }
  };

  const onCellPrepared = (e) => {
    e.cellElement.style.padding = "0px";
  };

  const onRowPrepared = (e) => {};

  //   const generatePDF = () => {
  //     const doc = new jsPDF({ orientation: "landscape" });
  //     doc.setFontSize(7);

  //     exportDataGridToPdf({
  //       jsPDFDocument: doc,
  //       component: dataGridRef.current.instance,
  //       customizeCell: ({ gridCell, pdfCell }) => {
  //         pdfCell.fontSize = 7;
  //         pdfCell.borderColor = "#FFFFFF";

  //         if (gridCell.rowType === "data") {
  //           if (gridCell.data.LINETYPE === "H") {
  //             pdfCell.fontStyle = "bold";
  //             pdfCell.backgroundColor = "#000000";
  //             pdfCell.textColor = "#FFFFFF";
  //           } else if (gridCell.data.LINETYPE === "X") {
  //             pdfCell.fontStyle = "bold";
  //             pdfCell.backgroundColor = "#D9D9D9";
  //             pdfCell.textColor = "#000000";
  //           } else if (gridCell.data.LINETYPE === "T") {
  //             pdfCell.fontStyle = "bold";
  //             pdfCell.backgroundColor = "#D9D9D9";
  //             pdfCell.textColor = "#000000";
  //           } else if (gridCell.data.DESCRIPTION === "") {
  //             pdfCell.text = "";
  //           } else {
  //             pdfCell.borderColor = null;
  //           }
  //           pdfCell.borderColor = "#FFFFFF";
  //         }
  //       },
  //     }).then(() => {
  //       doc.save("net_worth_summary.pdf");
  //     });
  //   };

  const renderDescriptionCell = (data) => {
    const { data: rowData } = data;

    let style = {};
    if (rowData.LINETYPE === "H") {
      style = {
        color: "white",
        backgroundColor: "black",
        borderTop: "1px solid black",
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
        borderLeft: "1px solid black",
      };
    } else if (rowData.LINETYPE === "X") {
      style = {
        color: "blue",
        backgroundColor: "lightgrey",
        borderTop: "1px solid black",
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
        borderLeft: "1px solid black",
      };
    } else if (rowData.LINETYPE === "T") {
      style = {
        color: "black",
        backgroundColor: "lightgrey",
        borderTop: "1px solid black",
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
        borderLeft: "1px solid black",
      };
    } else {
      style = {
        backgroundColor: "",
        borderRight: "1px solid black",
        borderLeft: "1px solid black",
      };
    }

    return <div style={style}>{rowData.DESCRIPTION}</div>;
  };

  const renderValueFieldCell = (data) => renderValueFieldCellCombined(data, 1);
  const renderValueFieldCell2 = (data) => renderValueFieldCellCombined(data, 2);
  const renderValueFieldCell3 = (data) => renderValueFieldCellCombined(data, 3);

  const renderValueFieldCellCombined = (data, column) => {
    const { data: rowData } = data;

    let style = { borderRight: "1px solid black" };
    let formattedValue = "";
    let displayValue = "";
    let printColumn = "";
    let valueField = "";
    let stringField = "";

    switch (column) {
      case 1:
        printColumn = rowData.PRINTCOLUMN1;
        valueField = rowData.VALUEFIELD;
        stringField = rowData.FIRSTSTRING;
        break;
      case 2:
        printColumn = rowData.PRINTCOLUMN2;
        valueField = rowData.VALUEFIELD2;
        stringField = rowData.SECONDSTRING;
        break;
      case 3:
        printColumn = rowData.PRINTCOLUMN3;
        valueField = rowData.VALUEFIELD3;
        stringField = rowData.THIRDSTRING;
        break;
      default:
    }

    if (rowData.LINETYPE === "T" || rowData.LINETYPE === "X") {
      style = {
        color: "black",
        backgroundColor: "#D9D9D9",
        borderTop: "1px solid black",
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
      };
    } else if (rowData.LINETYPE === "H") {
      style = {
        backgroundColor: "#E6D180",
        borderRight: "1px solid black",
      };
    }

    if (printColumn === "N") {
      style = {};
    }

    const isNegative = valueField < 0;
    const absoluteValue = Math.abs(valueField);

    formattedValue = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(absoluteValue);

    displayValue = isNegative ? `(${formattedValue})` : formattedValue;

    if (
      (rowData.LINETYPE === "H" ||
        rowData.LINETYPE === "X" ||
        rowData.LINETYPE === "B") &&
      printColumn !== "Y" &&
      printColumn !== "H"
    ) {
      return <div style={style}>&nbsp;</div>;
    } else if (printColumn === "H") {
      if (stringField === "") {
        return <div style={style}>&nbsp;</div>;
      } else {
        return (
          <div style={{ ...style, textAlign: "center" }}>{stringField}</div>
        );
      }
    } else {
      return <div style={style}>{displayValue}&nbsp;&nbsp;</div>;
    }
  };

  return (
    <>
      <style>
        {`
        .dx-datagrid-rowsview .dx-row {
          border: none !important;
        }

        .dx-datagrid-headers .dx-header-row {
          border: none !important;
        }

        .dx-datagrid-content .dx-cell {
          border: none !important;
        }
      `}
      </style>
      <div className="root">
        <h1>
          Detailed Net Worth Summary - {companyValues.PRESENTATIONDATE2} -{" "}
          {companyValues.PRESENTATIONNAME}
        </h1>
      </div>
      <>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ width: "35%" }}>
            <div className="content-combined-block">
              {/* <div className="content-combined-block dx-card responsive-paddings"> */}
              {/* <div className="custom-container1" style={{ height: "850px" }}> */}
              <div>
                <DataGrid
                  dataSource={mystore7(
                    props.clientCode,
                    ShowCreateClientPresentation,
                    debtType
                  )}
                  onRowPrepared={onRowPrepared}
                  onCellPrepared={onCellPrepared}
                  scrolling={{ mode: "virtual" }}
                  showBorders={false}
                  showRowLines={false}
                  remoteOperations={false}
                  onSelectionChanged={handleSelectionChanged}
                  onEditingStart={handleEditingStart}
                  width={"75%"}
                  height={"100%"}
                  ref={dataGridRef} // Using the reference here
                >
                  <FilterRow visible={showFilterRow} applyFilter={"auto"} />
                  <HeaderFilter visible={showHeaderFilter} />
                  <Paging enabled={false} />
                  <Column
                    dataField="UNIQUEID"
                    caption="Unique ID"
                    visible={false}
                  />
                  <Column dataField="ROWNUMBER" caption="Row" visible={false} />
                  <Column
                    dataField="DESCRIPTION"
                    //                    caption={`Net Worth for ${props.clientCode}`}
                    caption={""}
                    width={350}
                    visible={true}
                    cellRender={renderDescriptionCell}
                  />
                  <Column
                    dataField="VALUEFIELD"
                    caption=""
                    format={"$###,###,###"}
                    alignment="right"
                    cellRender={renderValueFieldCell}
                    width={100}
                  />
                  <Column dataField="LINETYPE" caption="Type" visible={false} />
                </DataGrid>
              </div>
            </div>
          </div>

          <div style={{ width: "25%" }}>
            {/* <div className="content-block2 dx-card responsive-paddings"> */}
            <div className="content-combined-block">
              <>
                <CFStructureResultInvestments
                  clientCode={props.clientCode}
                  ShowCreateClientPresentation={
                    props.ShowCreateClientPresentation
                  }
                />
              </>
            </div>
          </div>

          {/* <div style={{ display: "flex", flexDirection: "row" }}> */}
          <div style={{ width: "35%" }}>
            <div className="content-combined-block">
              <div>
                {/* <div className="content-block dx-card responsive-paddings">
              <div className="custom-container1" style={{ height: "850px" }}> */}
                <DataGrid
                  dataSource={mystore8(
                    props.clientCode,
                    ShowCreateClientPresentation,
                    investmentType
                  )}
                  onRowPrepared={onRowPrepared}
                  onCellPrepared={onCellPrepared}
                  scrolling={{ mode: "virtual" }}
                  showBorders={false}
                  showRowLines={false}
                  remoteOperations={false}
                  onSelectionChanged={handleSelectionChanged}
                  onEditingStart={handleEditingStart}
                  width={"75%"}
                  height={"100%"}
                  ref={dataGridRef} // Using the reference here
                >
                  <FilterRow visible={showFilterRow} applyFilter={"auto"} />
                  <HeaderFilter visible={showHeaderFilter} />
                  <Paging enabled={false} />
                  <Column
                    dataField="UNIQUEID"
                    caption="Unique ID"
                    visible={false}
                  />
                  <Column dataField="ROWNUMBER" caption="Row" visible={false} />
                  <Column
                    dataField="DESCRIPTION"
                    //                    caption={`Net Worth for ${props.clientCode}`}
                    caption={""}
                    width={350}
                    visible={true}
                    cellRender={renderDescriptionCell}
                  />
                  <Column
                    dataField="VALUEFIELD"
                    caption=""
                    format={"$###,###,###"}
                    alignment="right"
                    cellRender={renderValueFieldCell}
                    width={100}
                  />
                  <Column dataField="LINETYPE" caption="Type" visible={false} />
                </DataGrid>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </>
    </>
  );
};

//export default CombinedComponent;

export default function CombinedComponent(props) {
  const { user } = useAuth();
  console.log("what is props in progress summary not x :", { props });
  return (
    <CombinedComponentx
      clientCode={user.thisClientcode}
      thisWidth={props.thisWidth}
      showPrior={props.showPrior}
      ShowCreateClientPresentation={props.ShowCreateClientPresentation}
    />
  );
}
